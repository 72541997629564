
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { Actions } from "../../../store/enums/StoreEnums";
  import { getIllustrationsPath } from "../../../core/helpers/assets";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import { ref,unref } from "vue";
  import { reactive } from "vue";
  import { EnumsConstant } from "@/store/enums/constants";

  import router from "@/router";
  import { postManualOrderRequest } from "@/latipay/apis/services/ManualOrdersService";
  import { ElMessage } from "element-plus";
  import { getAccountsRequest } from "@/latipay/apis/services/AccountsService";
  import { reinitializeComponents } from "@/core/plugins/keenthemes";
  import {
    AddDecreaseManualOrder,
    AddIncreaseManualOrder, AddTransferManualOrder, ConversionRate,
    ManualOrdersResponse
  } from "@/latipay/apis/models/manualOrder/ManualOrdersResponse";

  export default defineComponent({
    name: "manualOrdersDetail",
    components: {},
    props: {
      id: String
    },
    setup(props) {
      const store = useStore();
      let orderType = "";
      const id = props.id ?? "";
      if (id === "increase") {
        orderType = "Increase";
      } else if (id === "decrease") {
        orderType = "Decrease";
      } else if (id === "transfer") {
        orderType = "Transfer";
      }

      const currencies = EnumsConstant.CurrencyCode;
      const increaseFormRef = ref();
      const decreaseFormRef = ref();
      const transferFormRef = ref();
      const loadingRef = ref(false);


      const increaseForm = reactive({
        accountToId: "",
        type: "",
        tag: "",
        balanceTypeTo: "",
        amountTo: "",
        comment: ""
      });
      const decreaseForm = reactive({
        accountFromId: "",
        type: "",
        tag: "",
        balanceTypeFrom: "",
        amountFrom: "",
        currencyFrom: "",
        comment: ""
      });
      const transferForm = reactive({
        accountFromId: "",
        accountToId: "",
        amountTo: "",
        currencyTo: "",
        amountFrom: "",
        currencyFrom: "",
        balanceTypeFrom: "",
        balanceTypeTo: "",
        tag: "",
        type: "",
        comment: "",
        rate:""
      });

      const successAlert = (message => {
        ElMessage({
          showClose: true,
          message: message,
          type: "success"
        });
      });

      const failAlert = (message => {

        ElMessage.error(message)
      });

      onMounted(async () => {
        await getAccountsData();
      });

      const {
        accountsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        getAccounts
      } = getAccountsRequest();


      const getAccountsData = async () => {
        loadingRef.value = true;

        // await updateQueries();
        await getAccounts(
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          1,
          10000
        );
        reinitializeComponents();

        loadingRef.value = false;

       if ( accountsRef.value ){
         accountsRef.value = accountsRef.value.filter(it => it.enabled === true);
       }
        console.log(accountsRef);
      };

      const { postErrorRef, postManualOrderRef, postManualOrder } = postManualOrderRequest();

      const onSubmitIncrease = async () => {
        const form = unref(increaseFormRef);
        if (!form) {
          return
        }
        try {
          await form.validate();


            const newIncrease = new AddIncreaseManualOrder();

          newIncrease.accountToId = +increaseForm.accountToId;
          newIncrease.tag = increaseForm.tag;
          newIncrease.orderType = increaseForm.type;
          newIncrease.balanceTypeTo = increaseForm.balanceTypeTo;
          newIncrease.amountTo = +increaseForm.amountTo;
          if (accountsRef.value){
             if (accountsRef.value.filter( it => it.id === +increaseForm.accountToId)[0]){
               newIncrease.currencyTo = accountsRef.value.filter( it => it.id === +increaseForm.accountToId)[0].currency
             }
          }
          newIncrease.comment = increaseForm.comment;
          newIncrease.type = 'increase';

            await postManualOrderInfo( newIncrease as unknown as ManualOrdersResponse);


        } catch (err) {
          loadingRef.value = false;
          console.log(err)
        }



      };
      const onSubmitDecrease = async () => {

        const form = unref(decreaseFormRef);
        if (!form) {
          return
        }
        try {
          await form.validate();


          const newDecrease = new AddDecreaseManualOrder();

          newDecrease.accountFromId = +decreaseForm.accountFromId;
          newDecrease.tag = decreaseForm.tag;
          newDecrease.orderType = decreaseForm.type;
          newDecrease.balanceTypeFrom = 'Available';
          newDecrease.amountFrom = +decreaseForm.amountFrom;
          if (accountsRef.value){
            if (accountsRef.value.filter( it => it.id === +decreaseForm.accountFromId)[0]){
              newDecrease.currencyFrom= accountsRef.value.filter( it => it.id === +decreaseForm.accountFromId)[0].currency
            }
          }
          newDecrease.comment = increaseForm.comment;
          newDecrease.type = 'decrease';

          await postManualOrderInfo( newDecrease as unknown as ManualOrdersResponse);


        } catch (err) {
          loadingRef.value = false;
          console.log(err)
        }

      };
      const onSubmitTransfer = async () => {
        const form = unref(transferFormRef);
        if (!form) {
          return
        }
        try {
          await form.validate();


          const newTransfer = new AddTransferManualOrder();

          if (+transferForm.accountFromId === +transferForm.accountToId  && transferForm.type === 'Adjust' ) {

            if (transferForm.balanceTypeFrom === 'Available'){
              newTransfer.balanceTypeFrom = transferForm.balanceTypeFrom;
              newTransfer.balanceTypeTo = 'Unavailable';
            }else{
              newTransfer.balanceTypeFrom = 'Unavailable';
              newTransfer.balanceTypeTo = 'Available';
            }
            newTransfer.amountFrom = +transferForm.amountFrom;
            newTransfer.amountTo = +transferForm.amountFrom;
            if (accountsRef.value) {
              if (accountsRef.value.filter(it => it.id === +transferForm.accountFromId)[0]) {
                newTransfer.currencyFrom = accountsRef.value.filter(it => it.id === +transferForm.accountFromId)[0].currency
              }
            }
            newTransfer.currencyTo = newTransfer.currencyFrom;

          }else if (+transferForm.accountFromId !== +transferForm.accountToId  && transferForm.type !== 'Exchange') {
            if (accountsRef.value) {

                  if (accountsRef.value.filter(it => it.id === +transferForm.accountFromId)[0].currency === accountsRef.value.filter(it => it.id === +transferForm.accountToId)[0].currency){

                    console.log("111");

                    newTransfer.balanceTypeFrom = 'Available';
                    newTransfer.balanceTypeTo = 'Available';
                    newTransfer.amountFrom = +transferForm.amountFrom;
                    newTransfer.amountTo = +transferForm.amountFrom;
                    newTransfer.currencyFrom = accountsRef.value.filter(it => it.id === +transferForm.accountFromId)[0].currency;
                    newTransfer.currencyTo = newTransfer.currencyFrom;

                  }

            }
          }else if(+transferForm.accountFromId !== +transferForm.accountToId  && transferForm.type === 'Exchange'){
            if (accountsRef.value) {

              if (accountsRef.value.filter(it => it.id === +transferForm.accountFromId)[0].currency !== accountsRef.value.filter(it => it.id === +transferForm.accountToId)[0].currency){

                console.log("111");

                newTransfer.balanceTypeFrom = 'Available';
                newTransfer.balanceTypeTo = 'Available';
                newTransfer.amountFrom = +transferForm.amountFrom;
                newTransfer.currencyFrom = accountsRef.value.filter(it => it.id === +transferForm.accountFromId)[0].currency;
                newTransfer.currencyTo = accountsRef.value.filter(it => it.id === +transferForm.accountToId)[0].currency;

                const newRate = new ConversionRate();
                newRate.currency = accountsRef.value.filter(it => it.id === +transferForm.accountFromId)[0].currency+'/'+accountsRef.value.filter(it => it.id === +transferForm.accountToId)[0].currency;
                newRate.rate = +transferForm.rate;
                newTransfer.conversionRate = JSON.stringify(newRate);
              }

            }


          }
          newTransfer.orderType = transferForm.type;
          newTransfer.accountFromId = +transferForm.accountFromId;
          newTransfer.accountToId = +transferForm.accountToId;
          newTransfer.tag = transferForm.tag;
          newTransfer.remarks = transferForm.comment;
          newTransfer.type = 'transfer';

          await postManualOrderInfo( newTransfer as unknown as ManualOrdersResponse);


        } catch (err) {
          loadingRef.value = false;
          console.log(err)
        }
      };




      const postManualOrderInfo = async ( data: ManualOrdersResponse) => {
        loadingRef.value = true;
        await postManualOrder(data);

        if (!postErrorRef.value) {
          loadingRef.value = false;

          successAlert("Add manual order successfully!");
          router.go(-1);



        }else {
          loadingRef.value = false;
          failAlert("Something wrong happened! Please have a check.");
        }


      };


      return {
        getIllustrationsPath,
        increaseForm,
        decreaseForm,
        transferForm,
        onSubmitIncrease,
        onSubmitDecrease,
        onSubmitTransfer,
        orderType,
        currencies,
        increaseFormRef,
        loadingRef,
        decreaseFormRef,
        transferFormRef,
        accountsRef

      };
    }
  });
